import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"

const Kuratorenfuehrung = () => {
  const data = useStaticQuery(graphql`
    query Kuratorenfuehrung {
      doeringCover: file(
        relativePath: { eq: "veranstaltungen/doering-hoelderlin.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Es brennt! In der Nacht zum 14. Dezember 1875 steht die »Eberhardtei« lichterloh in Flammen. 
        Viele kennen das Haus am Neckar wegen seiner beliebten Warmbadeanstalt. Und weitere Unglücke folgen: 
        Die Stadt wird danach von einer ganzen Serie verheerender Brände heimgesucht. Das Gerücht vom Tübinger Feuerteufel geht um. 
        Der Hund des Badbetreibers Eberhardt bleibt vermisst und der Schriftsteller Bruno Wille erinnert an Mörikes Ballade vom Feuerreiter.
        Was hat dies alles mit Hölderlin zu tun? Und vor allem: Wer ist für die mutmaßliche Brandstiftung verantwortlich?
      </Paragraph>
      <Paragraph>
        Florian Mittelhammer bringt in der Führung Licht ins Dunkel dieses Kriminalfalls und gibt auch einen Einblick in die Kuratierungsarbeit.
      </Paragraph>
    </Stack>
  )
}

export default Kuratorenfuehrung