import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"


const Mishra = () => {
  const data = useStaticQuery(graphql`
    query Mishra {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Zu Beginn des Jahres darf das Museum Hölderlinturm mit Sudesh Mishra einen internationalen Gast willkommen heißen: 
        In Kooperation mit dem College of Fellows, der Cluster-Initiative „Critical Proximities“ und dem SLT findet 
        mit dem Wissenschaftler - er ist Professor für Literatur an der University of the South Pacific, Fidschi - 
        und Schriftsteller eine Lesung seiner poetischen Texte statt. Mit dieser Veranstaltung möchten wir Sudesh Mishra 
        nicht nur als Wissenschaftler in Tübingen begrüßen, sondern ihn - nicht zuletzt vor dem Hintergrund der Bemühungen 
        um den Aufbau von Forschungsbeziehungen im Bereich artistic research und des Hölderlinturms um den Auftrag 
        der poetischen Bildung - auch mit seiner Dichtung vorstellen.
      </Paragraph>
      <Paragraph>
        Sudesh Mishra hat versprochen, bei dieser Gelegenheit ein neues Gedicht über Hölderlin-Celan vorzutragen.
      </Paragraph>
      <Paragraph>
        Poetische Publikationen:
        <BulletList>
          <Bullet>Rahu (Vision, 1987)</Bullet>
          <Bullet>Tandava (Meanjin Press, 1992)</Bullet>
          <Bullet>Memoirs of a Reluctant Traveller (Wakefield Press, 1994)</Bullet>
          <Bullet>Diaspora and the difficult art of dying (University of Otago, 2002)</Bullet>
          <Bullet>The Lives of Coat Hangers (University of Otago, 2016)</Bullet>
        </BulletList>
      </Paragraph>
      <Paragraph>
        Am Nachmittag vor der Lesung findet von 15 bis 17 Uhr im Seminarraum des College of Fellows (Villa Köstlin, Rümelinstr. 27) 
        ein Schreibworkshop mit Sudesh Mishra statt, in dessen Mittelpunkt die Frage steht, wie Dichter mittels der Sprache 
        die Welt neu erfinden. Auch hierzu sind alle Interessierten, insbesondere Studierende und weitere Personen, 
        die selbst schreiben, herzlich eingeladen.
      </Paragraph>
    </Stack>
  )
}

export default Mishra