import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Heading from "../../components/heading"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Familienfuehrung = () => {
  const data = useStaticQuery(graphql`
    query Familienfuehrung {
      doeringCover: file(
        relativePath: { eq: "veranstaltungen/doering-hoelderlin.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Es brennt! In der Nacht zum 14. Dezember 1875 steht die »Eberhardtei« lichterloh in Flammen. 
        Viele kennen das Haus am Neckar wegen seiner beliebten Warmbadeanstalt. Und weitere Unglücke folgen: 
        Die Stadt wird danach von einer ganzen Serie verheerender Brände heimgesucht. Das Gerücht vom Tübinger Feuerteufel geht um. 
        Der Hund des Badbetreibers Eberhardt bleibt vermisst und der Schriftsteller Bruno Wille erinnert an Mörikes Ballade vom Feuerreiter.
        Was hat dies alles mit Hölderlin zu tun? Und vor allem: Wer ist für die mutmaßliche Brandstiftung verantwortlich?
      </Paragraph>
      <Paragraph>
        Florian Mittelhammer bringt in der Führung Licht ins Dunkel dieses Kriminalfalls und gibt auch einen Einblick in die Kuratierungsarbeit.
      </Paragraph>
      <Stack space={6}>
              <Heading as="h2" level={5}>
                Anmeldung
              </Heading>
              <Paragraph>
                  Buchung nur über die FBS:{" "}
                  <ExternalLink href="https://www.fbs-tuebingen.de/familienaktivitaeten/kw/bereich/kursdetails/kurs/242-2512/kursname/Familienfuehrung+im+Hoelderlinturm/kategorie-id/43/#inhalt">
                    <span style={{color: '#F7A61B'}}> Zur Buchung</span> 
                  </ExternalLink>
              </Paragraph>
      </Stack>
    </Stack>
  )
}

export default Familienfuehrung